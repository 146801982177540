import React from "react";
import "react-loading-skeleton/dist/skeleton.css";
import {
  Route,
  Routes,
  unstable_HistoryRouter as HistoryRouter,
} from "react-router-dom";
import customHistory from "./router/customHistory";

import "./styles.scss";
// Prime React
import "primeicons/primeicons.css";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
/*
Note: Not using prime flex because css will mix up. Although best thing would be to reconfigure everything using a single library: possibly prime react. 
*/
// import 'primeflex/primeflex.css';

// pages
import AdminAccount from "./pages/admin/adminAccountPage";
import Advice from "./pages/admin/advicePage";
import AnalyseClient from "./pages/admin/analyseClientPage";
import AnalyseExpert from "./pages/admin/analyseExpertPage";
import CabinetConfigurationPage from "./pages/admin/cabinetConfiguration";
import ClientList from "./pages/admin/clientListPage";
import ConfigurationPackage from "./pages/admin/configurationPackage";
import Consultation from "./pages/admin/consultationPage";
import Expert from "./pages/admin/expertPage";
import Home from "./pages/admin/homePage";
import Planning from "./pages/admin/planningPage";
import PromoCode from "./pages/admin/promoCode";
import PurchasePackage from "./pages/admin/purchasePackagePage";
// expert
import AdviceExpert from "./pages/expert/advice";
import ConsultationExpert from "./pages/expert/consultation";
import Conversation from "./pages/expert/conversation";
import HomePageExpert from "./pages/expert/homePage";
import MailsExpert from "./pages/expert/mails";
import MyMessagesExpert from "./pages/expert/myMessages";
import MyPlanning from "./pages/expert/myPlanning";
import ProfileExpert from "./pages/expert/profile";
import StatistiqueExpert from "./pages/expert/statistique";
// client
import ExpertListClient from "./pages/client/expertList";
import MyConsultationsClient from "./pages/client/myConsultations";
import MyMeetingClient from "./pages/client/myMeeting";
import MyMethodsPayementClient from "./pages/client/myMethodsPayement";
import MyPurchaseClient from "./pages/client/myPurchase";
import PayementClient from "./pages/client/payement";
import ProfileClient from "./pages/client/profile";
// path
import Layout from "./components/layout/layout";
import NewPackage from "./pages/admin/configurationPackage/newPackage";
import NewPromoCode from "./pages/admin/promoCode/newPromoCode";
import ExpertProfilePage from "./pages/client/expertProfile";
import PaymentEmailPage from "./pages/client/paymentEmailPage";
import PaymentRdvPage from "./pages/client/paymentRdvPage";
import LoginPage from "./pages/login";
import PasswordReset from "./pages/password-reset";
import RegisterPage from "./pages/register";
import EditPackage from "./pages/admin/configurationPackage/editPackage";
import EditPromoCode from "./pages/admin/promoCode/editPromoCode";
import ModifyPassword from "./pages/modifyPassword";
import Email from "./pages/admin/email";
import MailsClient from "./pages/client/mails";
import ConversationClient from "./pages/client/conversation";
import OwnerHomePage from "./pages/owner/homePage";
import NotFoundRedirect from "./pages/404";

//Webhook
import ForfaitsPage from "./pages/webhook/forfaits";
import NosExperts from "./pages/webhook/nosExpert";
import ProfilePage from "./pages/webhook/profile";
import ClientPage from "./pages/admin/clientPage";

function App() {
  return (
    <HistoryRouter history={customHistory}>
      <Routes>
        {/*----------------- Owner Routes --------------------------------------*/}
        <Route path="/:officeName/owner" element={<Layout role="owner" />}>
          <Route index Component={OwnerHomePage} />
        </Route>
        {/*----------------- Admin Routes --------------------------------------*/}
        <Route path="/:officeName/admin" element={<Layout role="admin" />}>
          <Route index Component={Home} />
          <Route path="consultation" Component={Consultation} />
          <Route path="planning/:calendarType" Component={Planning} />
          <Route path="experts" Component={Expert} />
          <Route path="analyseExpert" Component={AnalyseExpert} />
          <Route path="clientList" Component={ClientList} />
          <Route path="client/:clientId" Component={ClientPage} />
          <Route path="analyseClient" Component={AnalyseClient} />
          <Route path="promoCode">
            <Route index Component={PromoCode} />
            <Route path="new" Component={NewPromoCode} />
            <Route path="edit" Component={EditPromoCode} />
          </Route>
          <Route path="advice" Component={Advice} />
          <Route path="purchasePackage" Component={PurchasePackage} />
          <Route path="configurationPackage">
            <Route index Component={ConfigurationPackage} />
            <Route path="new" Component={NewPackage} />
            <Route path="edit" Component={EditPackage} />
          </Route>
          <Route
            path="cabinetConfiguration"
            Component={CabinetConfigurationPage}
          />
          <Route path="adminAccount" Component={AdminAccount} />
          <Route path="email" Component={Email} />
        </Route>

        {/*----------------- Expert Routes --------------------------------------*/}
        <Route path="/:officeName/expert" element={<Layout role="expert" />}>
          <Route index Component={HomePageExpert} />
          <Route path="mymessages" Component={MyMessagesExpert} />
          <Route path="consultation" Component={ConsultationExpert} />
          <Route path="mails" Component={MailsExpert} />
          <Route path="advice" Component={AdviceExpert} />
          <Route path="profile" Component={ProfileExpert} />
          <Route path="planning/:calendarType" Component={MyPlanning} />
          <Route path="conversation" Component={Conversation} />
          <Route path="statistique" Component={StatistiqueExpert} />
        </Route>
        {/*----------------- Client Routes --------------------------------------*/}
        <Route path="/:officeName/client" element={<Layout role="client" />}>
          <Route index Component={ProfileClient} />
          <Route path="expertList" Component={ExpertListClient} />
          <Route
            path="expertList/:expertId/"
            Component={ExpertProfilePage}
          />
          <Route
            path="expertList/:expertId/:call"
            Component={ExpertProfilePage}
          />
          <Route path="mails" Component={MailsClient} />
          <Route path="conversation" Component={ConversationClient} />
          <Route path="myconsultations" Component={MyConsultationsClient} />
          <Route path="mypurchase" Component={MyPurchaseClient} />
          <Route path="payement" Component={PayementClient} />
          <Route path="mymeeting" Component={MyMeetingClient} />
          <Route path="mymethodspayement" Component={MyMethodsPayementClient} />
          <Route path="payment-email/:expertId" Component={PaymentEmailPage} />
          <Route path="payment-rdv/:expertId" Component={PaymentRdvPage} />
        </Route>
        {/*----------------- Webhook Routes --------------------------------------*/}
        <Route path="/webhook/:officeName/forfaits" Component={ForfaitsPage} />
        <Route path="/webhook/:officeName/profile/:expertId" Component={ProfilePage} />
        <Route path="/webhook/:officeName/experts/:profileUrl" Component={NosExperts} />
        {/*----------------- General Routes --------------------------------------*/}
        <Route path="/:officeName/modify-password/:token/:email" Component={ModifyPassword} />
        <Route path="/:officeName/login" Component={LoginPage} />
        <Route path="/:officeName/register" Component={RegisterPage} />
        <Route path="/:officeName/password" Component={PasswordReset} />
        <Route path="*" element={<NotFoundRedirect />} />
      </Routes>
    </HistoryRouter>
  );
}

export default App;
