import apiClient from "../apiClient";

export const profileClient = {
    expert:{
        getByCategory: (expertType, officeName) => apiClient.get('/profiles/offices/experts/all/view/',{
            params:{
                expertType: expertType,
                officeName: officeName
            }
        }),
        getPublicProfile: (expertId) => apiClient.get('/profiles/experts/public/view/',{
            params:{
                expertId: expertId
            }
        })
    },
    client:{
        getAll: (dateStart,dateEnd) => apiClient.get(`/profiles/admins/clients/all/view/?dateStart=${dateStart}&dateEnd=${dateEnd}`)
    }
}