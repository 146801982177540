import { Icon } from "@iconify/react";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoMdNotificationsOutline } from "react-icons/io";
import AvailabilityChip from "../chip/availabilityChip";
import HeaderSkeleton from "../skeletons/layout/headerSkeleton";
import { useEffect } from "react";
import { notificationClient } from "./../../network/lib/notification";
import Notification from "../notification";
import { useState } from "react";

function Header(props) {
  const { isLoading, role, notification } = props;
  const [notificationData, setNotificationData] = useState({})

  const fetchNotifications = () => {
    notificationClient.getAllUnread().then((res) => {
      setNotificationData(res.data.notifications);
    });
  }

  useEffect(() => {
    if (role === "admin") {
     fetchNotifications()
    }
  }, []);

  if (isLoading) {
    return <HeaderSkeleton />;
  }

  return (
    <header className="header">
      <GiHamburgerMenu
        className="burger-menu"
        onClick={() => props.setShowBatMenu(!props.showBatMenu)}
      />
      <img src={props.logo} className="logo" alt="logo" />

      <ul className="menu">
        {role === "expert" ? (
          <li>
            <AvailabilityChip />
          </li>
        ) : null}

        <li className="languge">
          <Icon icon="ic:round-language" /> <span>Français</span>
        </li>
        {role === "admin" && (
          <>
            {/* <li className="notification" data-nbnotif={notification}>
              <IoMdNotificationsOutline />
            </li> */}
            <Notification notifications={notificationData} fetchNotifications={fetchNotifications}/>
          </>
        )}

        <li className="profile">
          <img
            src={props.userImage ? props.userImage : "/assets/profile.png"}
            alt="profile"
            className="profile-image"
          />
          <span className="profile-name">{props.userName}</span>
        </li>
      </ul>
    </header>
  );
}

export default Header;
