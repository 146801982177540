import { CloudServiceManagement } from "@carbon/icons-react";
import { Icon } from "@iconify/react";
import { BiVideo } from "react-icons/bi";
import { FaUserAlt } from "react-icons/fa";
import { HiOutlineArrowRight } from "react-icons/hi";
import { IoIosSettings } from "react-icons/io";
import { MdCalendarMonth, MdVerified } from "react-icons/md";
import LogoutHandler from "../../utils/logout";


function BatMenuAdmin(props) {

    const { navigation } = props;
  
    return (
      <div id="bat-menu" className={`${props.showBatMenu ? "show" : ""}`}>
        <div>
          <span className="title">ADMINISTRATEUR</span>
          <ul className="menu">
            <li
              className="selected"
              onClick={() => navigation("/" + props.officeName + "/admin/")}
            >
              <Icon icon="ion:logo-tableau" />
              <span>Tableau de bord</span>
            </li>
            <li
              onClick={() =>
                navigation("/" + props.officeName + "/admin/consultation")
              }
            >
              <BiVideo />
              <span>Consultations en cours</span>
            </li>
            <li>
              <MdCalendarMonth />
              <span>Planning</span>
            </li>
            <li
              className="shift open"
              onClick={() => navigation("/" + props.officeName + "/admin/planning/classic")}
            >
              <HiOutlineArrowRight />
              <span>Centrale</span>
            </li>
            <li
              className="shift open"
              onClick={() => navigation("/" + props.officeName + "/admin/planning/appointment")}
            >
              <HiOutlineArrowRight />
              <span>Rendez-vous</span>
            </li>
            <li>
              <MdVerified />
              <span>Expert</span>
            </li>
            <li
              className="shift open"
              onClick={() => navigation("/" + props.officeName + "/admin/experts")}
            >
              <HiOutlineArrowRight />
              <span>Compte</span>
            </li>
            <li
              className="shift open"
              onClick={() =>
                navigation("/" + props.officeName + "/admin/analyseExpert")
              }
            >
              <HiOutlineArrowRight />
              <span>Analyse</span>
            </li>
            <li>
              <FaUserAlt />
              <span>Client</span>
            </li>
            <li
              className="shift open"
              onClick={() =>
                navigation("/" + props.officeName + "/admin/clientList")
              }
            >
              <HiOutlineArrowRight />
              <span>Compte</span>
            </li>
            <li>
              <Icon icon="fluent-mdl2:product-list" />
              <span>Forfait</span>
            </li>
            <li
              className="shift open"
              onClick={() =>
                navigation("/" + props.officeName + "/admin/configurationPackage")
              }
            >
              <HiOutlineArrowRight />
              <span>Liste</span>
            </li>
            <li>
              <CloudServiceManagement />
              <span>Gestion</span>
            </li>
            <li
              className="shift open"
              onClick={() =>
                navigation("/" + props.officeName + "/admin/email")
              }
            >
              <HiOutlineArrowRight />
              <span>Emailing</span>
            </li>
            <li
              className="shift open"
              onClick={() =>
                navigation("/" + props.officeName + "/admin/adminAccount")
              }
            >
              <HiOutlineArrowRight />
              <span>Administrateur</span>
            </li>
            <li
              className="shift open"
              onClick={() =>
                navigation("/" + props.officeName + "/admin/promoCode")
              }
            >
              <HiOutlineArrowRight />
              <span>Promotion</span>
            </li>
            <li
              className="shift open"
              onClick={() => navigation("/" + props.officeName + "/admin/advice")}
            >
              <HiOutlineArrowRight />
              <span>Avis</span>
            </li>
          </ul>
        </div>
  
        <div>
          <span className="title">AUTRE</span>
          <ul className="menu">
            <li
              onClick={() =>
                navigation("/" + props.officeName + "/admin/cabinetConfiguration")
              }
            >
              <IoIosSettings />
              <span>Paramètres</span>
            </li>
            <LogoutHandler officeName={props.officeName} />
          </ul>
        </div>
      </div>
    );
  }

  export default BatMenuAdmin;