import { Badge } from "primereact/badge";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import React, { useEffect, useRef, useState } from "react";
import { notificationClient } from "../../network/lib/notification";

const Notification = ({ notifications, fetchNotifications }) => {
  const menuRight = useRef(null);
  const [items, setItems] = useState([]);

  const markReadNotification = (notification) => {
    notificationClient.markRead(notification.notificationId).then((res) => {
      console.log(notification.notificationId, " marked read");
      fetchNotifications();
    });
  };

  const handleButtonClick = (e) => {
    if (notifications.length !== 0) {
      menuRight.current.toggle(e);
    }
  };

  useEffect(() => {
    if (notifications.length > 0) {
      setItems(
        notifications.map((notification) => {
          return {
            label: (
              <p style={{ fontSize: "1.2rem", padding: "5px 20px 0px 5px" }}>
                {notification.message}
              </p>
            ),
            command: () => {
              markReadNotification(notification);
            },
          };
        })
      );
    }
  }, [notifications]);

  //   console.log(notifications);
  return (
    <div className="notification-button">
      <Menu
        model={items}
        popup
        ref={menuRight}
        id="popup_menu_right"
        popupAlignment="right"
        style={{ minWidth: "400px" }}
      />
      <Button
        icon="pi pi-bell"
        rounded
        text
        severity="secondary"
        aria-label="Notification"
        onClick={(e) => handleButtonClick(e)}
      />
      <Badge
        value={notifications.length}
        className="notification-badge"
      ></Badge>
    </div>
  );
};

export default Notification;
