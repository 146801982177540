import { Icon } from "@iconify/react";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PaymentConfirm from "../../components/payment/PaymentConfirm";
import CustomSelectId from "../../components/utils/CustomSelectId";
import {
  fireErrorAlert,
  fireSuccessAlert
} from "./../../components/utils/Swal";
import { paymentClient } from "./../../network/lib/payment";
import { productClient } from "./../../network/lib/product";
import { promotionClient } from "./../../network/lib/promotion";
import NewCard from "../../components/payment/card/NewCard";

function PayementClient() {
  const { officeName } = useParams();

  const codePromoRef = useRef();

  const [productListData, productListDataSet] = useState([]);
  const [cardListData, cardListDataSet] = useState([]);
  const [productData, productDataSet] = useState([]);
  const [cardData, cardDataSet] = useState([]);
  const [cardNewData, cardNewDataSet] = useState();
  const [promotionalData, promotionalDataSet] = useState([]);

  const navigate = useNavigate();

  function codePromoCheck() {
    const promotional = {
      codeName: codePromoRef.current.value,
      productId: productData["id"],
    };

    promotionClient
      .checkPromoCode(promotional)
      .then((resp) => {
        promotionalDataSet(resp.data);
        fireSuccessAlert("La réduction à été appliqué");
      })
      .catch((error) => {
        if (error.response) {
          fireErrorAlert("Code invalide");
        }
      });
  }

  useEffect(() => {
    productClient.getAllByCategory("CALL").then((resp) => {
      console.log(resp.data);
      productListDataSet(resp.data.products);
    });

    paymentClient.card.getAll().then((resp) => {
      cardListDataSet(resp.data.cards);
    });
  }, []);

  console.log(cardListData)
  return (
    <div id="payementClient">
      <h2>Créditer mon compte</h2>

      <div className="form-control">
        <label>Je choisis mon forfait</label>
        <CustomSelectId
          onChange={(e) => productDataSet(productListData[e])}
          options={productListData}
        />
      </div>

      <hr style={{ marginTop: "27px" }} />

      <div className="form-control">
        <label>Code Promo</label>
        <div className="input-control">
          <div className="icon">
            <Icon icon="ic:outline-label" color="#000000" />
          </div>
          <input ref={codePromoRef} className="no-anime input-field" />
          <button onClick={() => codePromoCheck()}>OK</button>
        </div>
      </div>

      <hr style={{ marginTop: "40px" }} />

      <div className="split-line">
        <span>Total</span>
        <span>
          {promotionalData["price"]
            ? promotionalData["price"]
            : productData["price"]
            ? productData["price"]
            : "0"}{" "}
          € TTC
        </span>
      </div>

      {/*<div className="form-control">
        <label>Mes moyens de paiement</label>
        <CustomSelectId
          onChange={(e) => cardDataSet(cardListData[e])}
          options={cardListData}
        />
  </div>*/}

      {
        !cardData["cardId"] ? <NewCard cardNewSet={cardNewDataSet}/> : ""
      }

      {productData["id"] && (cardData["cardId"] || cardNewData) ? (
        <PaymentConfirm
          paymentUrl={"payments/easytransac/calls/create"}
          paymentData={{ productId: productData["id"] }}
          paymentCardId={cardData["cardId"]}
          paymentCardNew={cardNewData}
          codeName={promotionalData["codeName"]}
        />
      ) : ""}

      {/**<div className="box">
        <form>
          <div className="form-control">
            <label>Numéro de carte</label>
            <input />
          </div>
          <div className="col-2">
            <div className="form-control">
              <label>Date d’expiration</label>
              <input />
            </div>
            <div className="form-control">
              <label>CVC</label>
              <input />
            </div>
          </div>
          <div className="form-control">
            <label>Titulaire (Prénom, Nom inscrit sur la carte)</label>
            <input />
          </div>
          <div className="check-form">
            <input type="checkbox" />
            <label>Sauvegarder la carte?</label>
          </div>
          <div className="col-2-button">
            <button>Valider</button>
            <div className="col-flex">
              <Icon icon="material-symbols:lock" color="#F5CE00" />
              <span>Paiement sécurisé</span>
            </div>
          </div>
        </form>
        </div>**/}
    </div>
  );
}

export default PayementClient;
