import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import PaymentRdv from "../../components/client/paymentRdv";
import PaymentConfirm from "../../components/payment/PaymentConfirm";
import AdviceBoxSkeleton from "../../components/skeletons/expert/adviceBoxSkeleton";
import CustomSelectId from "../../components/utils/CustomSelectId";
import { fireErrorAlert, fireSuccessAlert } from "../../components/utils/Swal";
import { paymentClient } from "../../network/lib/payment";
import { profileClient } from "../../network/lib/profile";
import { promotionClient } from "../../network/lib/promotion";
import { productClient } from "./../../network/lib/product";
import NewCard from "../../components/payment/card/NewCard";

function PaymentRdvPage() {
  const { expertId } = useParams();
  const bookingState = useSelector((state) => state.booking);
  const [expertData, setExpertData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [cardNewData, cardNewDataSet] = useState();
  const [codePromo, setCodePromo] = useState("");
  const [promotionalData, setPromotionalData] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [cardList, setCardList] = useState([]);
  const [cardData, setCardData] = useState([]);
  const [paymentData, setPaymentData] = useState({});
  const [avaialbleDates, setAvailableDates] = useState([]);

  const navigate = useNavigate();
  const { officeName } = useParams();

  function codePromoCheck(e) {
    if (!selectedProduct) {
      console.log("Select a product first");
      return;
    }
    const promotional = {
      codeName: codePromo,
      productId: selectedProduct.id,
    };

    promotionClient
      .checkPromoCode(promotional)
      .then((resp) => {
        setPromotionalData(resp.data);
        fireSuccessAlert("La réduction à été appliqué");
      })
      .catch((error) => {
        if (error.response) {
          fireErrorAlert("Code invalide");
        }
      });
  }

  const createMockDates = () => {
    return [
      {
        years: 2023,
        month: 7,
        day: 30,
      },
      {
        years: 2023,
        month: 7,
        day: 31,
      },
      {
        years: 2023,
        month: 8,
        day: 1,
      },
      {
        years: 2023,
        month: 8,
        day: 2,
      },
      {
        years: 2023,
        month: 8,
        day: 3,
      },
      {
        years: 2023,
        month: 8,
        day: 4,
      },
      {
        years: 2023,
        month: 8,
        day: 5,
      },
    ];
  };

  useEffect(() => {
    profileClient.expert.getPublicProfile(expertId).then((resp) => {
      console.log(resp.data);
      setExpertData(resp.data);
      setIsLoading(false);
    });

    productClient.getAllByCategory("MEET").then((res) => {
      // console.log(res.data);
      setProducts(res.data.products);
    });

    paymentClient.card.getAll().then((resp) => {
      setCardList(resp.data.cards);
    });

    
    //Mock dates
    setAvailableDates(createMockDates());
  }, [bookingState]);

  if (isLoading) {
    return <AdviceBoxSkeleton count={6} />;
  }
  // console.log(paymentData);
  return (
    <div id="payementClient">
      <h2 className="mb-2">Prenez rendez-vous</h2>

      <div className="mb-2">
        <div className="profile">
          <div
            className="picture"
            style={{
              backgroundImage: `url("` + expertData.imageProfile + `")`,
            }}
          ></div>
          <h3 className="profile-name">{expertData.name}</h3>
        </div>
      </div>

      <PaymentRdv
        options={products}
        selectedProduct={selectedProduct}
        setSelectedProduct={setSelectedProduct}
        setPaymentData={setPaymentData}
        expertId={expertId}
        dates={avaialbleDates}
      />

      <hr style={{ marginTop: "27px" }} />

      <div className="form-control">
        <label className="text-bold">Code Promo</label>
        <div className="input-control">
          <div className="icon">
            <Icon icon="ic:outline-label" color="#000000" />
          </div>
          <input
            value={codePromo}
            onChange={(e) => setCodePromo(e.target.value)}
            className="no-anime input-field"
          />
          <button onClick={(e) => codePromoCheck(e)}>OK</button>
        </div>
      </div>

      <hr style={{ marginTop: "40px" }} />

      <div className="split-line">
        <span className="text-bold">Total</span>
        <span>{selectedProduct ? selectedProduct.price : 0}€ TTC</span>
      </div>

      {/*<div className="form-control">
        <label className="text-bold">Mes moyens de payement</label>
        {cardList.length === 0 ? (
          <Skeleton />
        ) : (
          <CustomSelectId
            value="Sélectionnez votre carte"
            onChange={(e) => setCardData(cardList[e])}
            options={cardList}
          />
        )}
      </div>*/}

      {/* <PaymentConfirm 
        paymentUrl={"payments/plans/meets/create"} 
        paymentData={{ productId: “UUID”, expertId: “UUID”, calendareId: “UUID”, slots: “Horaire en string”}} 
        paymentMethodId={cardData["cardId"]} 
        codeName={promotionalData["codeName"]}
      />
 */}
      {
        !cardData["cardId"] ? <NewCard cardNewSet={cardNewDataSet}/> : ""
      }

      {(cardData["cardId"] || cardNewData) ? (
        <PaymentConfirm
          paymentUrl={"payments/easytransac/meets/create"}
          paymentData={paymentData}
          paymentCardId={cardData["cardId"]}
          paymentCardNew={cardNewData}
          codeName={promotionalData["codeName"]}
        />
      ) : ""}
    </div>
  );
}

export default PaymentRdvPage;
