import BoxCa from "../../components/home/boxCa";
import BoxConsultations from "../../components/home/boxConsultations";
import BoxTd from "../../components/home/boxTd";

import DataChart from "../../components/utils/chart";

import { useEffect, useState } from "react";
import BoxCaSkeleton from "../../components/home/boxCaSkeleton";
import BoxConsultationsSekelton from "../../components/home/boxConsultationsSkeleton";
import BoxTdSkeleton from "../../components/home/boxTdSkeleton";
import ChartSkeleton from "../../components/home/chartSekelton";
import { statsClient } from "../../network/lib/stats";

function HomePage() {
  const [statsData, statsDataSet] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const config = {
    headers: {
      sessionId: localStorage.getItem("sessionId"),
    },
  };

  useEffect(() => {
    statsClient.admin.home.get()
      .then((resp) => {
        statsDataSet(resp.data);
      })
      .then((res) => {
        setIsLoading(false);
      })
      // .catch((error) => {
      //   //window.location.href = "https://vooxhub.com";
      // });
  }, []);

  return (
    <div id="home">
      <h2>Mon tableau de bord ({statsData.dateUpdate})</h2>
      <div className="grid">
        {isLoading ? <BoxCaSkeleton /> : <BoxCa stats={statsData} />}
        {isLoading ? (
          <BoxConsultationsSekelton />
        ) : (
          <BoxConsultations stats={statsData} />
        )}
        {isLoading ? <BoxTdSkeleton /> : <BoxTd stats={statsData} />}

        <div className="box box-eca">
          <h3>Évolution du chiffre d'affaires</h3>
          {isLoading ? <ChartSkeleton /> : <DataChart stats={statsData} />}
        </div>
        {/*<div className="box box-ar">
          <h3>Activité récente</h3>
          <div className="blocks">
            <NotifBlock
              type="success"
              title="Nouveau client"
              subTitle="Yanis vient de s’inscrire sur votre cabinet."
              date="8 mars 2023 | 18h38"
            />
            <NotifBlock
              type="info"
              title="Statut réinitialisé"
              subTitle="Yanis Two vient réinitialisé son statut."
              date="8 mars 2023 | 18h38"
            />
            <NotifBlock
              type="success"
              title="Nouveau client"
              subTitle="Yanis vient de s’inscrire sur votre cabinet."
              date="8 mars 2023 | 18h38"
            />
          </div>
        </div>*/}
      </div>
    </div>
  );
}

export default HomePage;
